.dropdown-content {
  display: block;
  height: 50vh;
  padding: 2%;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 14px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  /* float: none; */
  color: black;
  padding: 12px 14px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {
  background-color: #ddd;
}
.onIt {
  display: none;
}
.dropdown:hover .dropdown-content {
  display: block;
}

[data-reach-menu-button] {
  /* padding: 0.75em 1em; */
  background-color: #fafafa;
  border: none;
  border: 2px solid #ccc;
  border-radius: 5px;
  margin: 5px;
}
[data-reach-menu-button][aria-expanded="true"] {
  border-radius: 5px 5px 0 0;
}

[data-reach-menu-list] {
  border-radius: 0 0 5px 5px;
  border: 2px solid #ccc;
  background-color: white;
  transform: translateY(-2px);
}

.right-menuT {
  margin-top: -1%;
  position: relative;
  display: inline-block;
  

}
.right-menuT button{
  font-size: .8rem;

}
.menu-button {
  text-transform: uppercase;
  background-color: transparent;
  padding: 17px;
  padding-right: 35px;
  padding-left: 0;
  font-size: .8rem;
  cursor: pointer;
  font-weight: 500;
  width: max-content;
}
.menu-button:hover {
  font-weight: 700;
}
.my-dropdown-menu {
  display: none;
  position: absolute;
  background-color: #f9f9f9ff;
  width: max-content;
  z-index: 1;

  padding: 0;
  margin: 0;
  font-size: 0.875rem;
  text-align: left;
  list-style: none;
  background-color: #f9f9f9;
  background-clip: padding-box;
  border-radius: 4px;
}

.my-dropdown-menu a {
  display: block;
  text-decoration: none;
  padding: 12px 14px;
  color: darkolivegreen;
  width: 100%;
}
.my-dropdown-menu a:hover {
  color: white;
  background-color: #62c165;

  /* border-radius: 4px; */
}
.my-dropdown-menu a:first-child:hover {
  color: white;
  background-color: #62c165;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.my-dropdown-menu a:nth-child(3):hover {
  color: white;
  background-color: #62c165;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.my-dropdown-menu a:nth-child(even):hover {
  color: white;
  background-color: #62c165;
  border-radius: 0;
}
.right-menuT:hover .my-dropdown-menu {
  display: block;
}
.right-menuT:hover .my-menu-button {
  display: block;
}
.right-menuT:hover .testDis{
  display: none;
}
/* .menu {
  padding: 0;
}
.menu__item {
  
  padding: 6px 20px;
  list-style-type: none;
} */
.menu__item:hover {
  /* background-color: #5fb3ce; */
}
.menu__item {
  
  text-decoration: none;
}
.menu__item a:hover {
  color: #f33;
  text-decoration: underline;
}
.menu__item a:active {
  color: red;
}
/* .menu__active {
  outline-offset: -15px;
  border-bottom: 1px solid currentColor;
  
  

} */
.menu__active:after {
  content: ""; /* This is necessary for the pseudo element to work. */ 
  display: block; /* This will put the pseudo element on its own line. */
  margin: 0 auto; /* This will center the border. */
  width: 50%; /* Change this to whatever width you want. */
  /* padding-top: 20px; This creates some space between the element and the border. */
  border-bottom: 1px solid currentColor; /* This creates the border. Replace black with whatever color you want. */
}

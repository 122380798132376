:root {
    --animate-duration: 200ms;
    --animate-delay: 0.9s;
  }
.nav.nav-tabs .nav-link.active{
    background-color:#265854;
    color:white;
    border: solid 1px;
    border-bottom: #60eb7c;
    border-radius: 6px;
}
.nav.nav-tabs .nav-link:first-child:hover{
    background-color:rgba(34, 123, 95, 0.54);
    border-radius: 6px;
}